<template>
    <div class="audit">
        <rxNavBar title="配货申请审核" androidOrIOSFlag="true"></rxNavBar>
        <div class="head">
            <div class="search">
                <div class="search-icon" @click="goSearch"></div>
                <input type="text" placeholder="房源编号/房源地址/申请人" v-model="inputValue" @keyup.enter="goSearch">
            </div>
            <div class="types">
                <div class="type" @click="isShow = !isShow" id="downList">{{ typeList[number] ? typeList[number].dictionaryTitle : '未审批'}}</div>
                <div :class="isShow?'small-icon-rotate':'small-icon'"></div>
            </div>
<!--            &lt;!&ndash;下拉列表&ndash;&gt;-->
<!--            <div class="selectList" v-show="isShow">-->
<!--                <div class="select" :class="{selectActive:index===number}" @click="changeType(index)" v-for="(item,index) in typeList" :key="index">-->
<!--                    {{item}}-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <div class="auditDiv">
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh" >
            <van-list v-model="loading" :finished="finished" :finished-text="isEmptyFlag ? '' : '已经到底了' " @load="this.onLoad">
                <div class="stateList" v-for="(item,index) in orders"   :key="index" >
                    <div>
                        <!--                循环列表状态 -->
                        <div v-if="item.approvalStatus==0" class="stateUnapproval">
                            {{item.approvalStatusStr}}
                        </div>
                        <div v-if="item.approvalStatus==1" class="statePassed">
                            {{item.approvalStatusStr}}
                        </div>
                        <div v-if="item.approvalStatus==2" class="stateRejected">
                            {{item.approvalStatusStr}}
                        </div>
                        <!--                循环列表头-->
                        <div class="headInfo" >
                            {{item.roomDetailedAddress}}
                        </div>
                        <!--                循环列表详细信息-->
                        <div class="listDetail" >
                            <div class="proposerDiv">申请人</div>
                            <!--                    第一条竖线-->
                            <div class="rule1"></div>
                            <div class="budgetDiv">房源编号</div>
                            <!--                    第二条竖线-->
                            <div class="rule2"></div>
                            <div class="remouldDiv">费用承担</div>
                            <!--                    获取申请人的信息-->
                            <div class="proposerValueDiv">{{item.applicationGlobaluserName||item.inChangeStaffName}}</div>
                            <!--                    获取房源编号的信息-->
                            <div class="budgetValueDiv">{{item.houseCode}}</div>
                            <!--                    获取费用承担的信息-->
                            <div class="remouldValueDiv">{{item.costBearStr}}</div>
                        </div>
                        <!--                分割线-->
                        <div class="rule3" v-if="item.afterRentDesprition != ''"></div>
                        <div class="apply-result" v-if="item.afterRentDesprition != ''">
                            <span class="createMethodsDiv">申请原因:</span>
                            <span class="createTimeDiv">{{item.afterRentDesprition}}</span>

                        </div>
                        <!--                分割线-->
                        <div class="rule3"></div>
                        <!--                循环列表下部分-->
                        <div class="listFloor">
<!--                            <div v-if="item.approvalStatus==0" class="createMethodsDiv">申请时间 :</div>-->
<!--                            <div v-if="item.approvalStatus==1" class="createMethodsDiv">通过时间 : </div>-->
<!--                            <div v-if="item.approvalStatus==2" class="createMethodsDiv">驳回时间 : </div>-->
                            <div class="createDiv">
                                <span  class="createMethodsDiv">预计配送时间 : </span>
                                <span class="createTimeDiv">{{item.appointmentTime}}</span>
                            <!--审核按钮-->
                            </div>
                            <div>
                                <button class="btn1" v-if="item.approvalStatus==0 && checkAuthList(authButtonsList,'approval')" @click="auditDetailClick(index)">审核</button>
                                <!--方案查看按钮-->
                                <button class="btn2" v-else   @click="decorationSchemeAuditCheck(index)" >查看</button>
                            </div>
                                <!--方案查看按钮-->
<!--                                <button class="btn2" v-else   @click="decorationSchemeAuditCheck(index)" >查看</button>-->


                        </div>
                    </div>
                </div>
            </van-list>
            <common-empty v-if="isEmptyFlag"></common-empty>
            </van-pull-refresh>
        </div>
      <van-popup v-model="isShow" position="bottom">
        <van-picker show-toolbar :columns="typeList" @cancel="isShow = false" :default-index="number" value-key="dictionaryTitle" @confirm="changeType"/>
      </van-popup>
    </div>
</template>

<script>
    import { Button ,Search,DropdownMenu,DropdownItem,Divider,List,NavBar,Picker,
      Popup,PullRefresh } from 'vant';
    import {distributionListInit, queryBaseData,getStaffFuntionModelList} from "../../getData/getData";
    import {getStaffId, responseUtil, checkAuthList, globaluserId} from "../../libs/rongxunUtil";
    import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
    export default {
        components: {
            [Button.name]:Button,
            [Search.name]:Search,
            [DropdownMenu.name]:DropdownMenu,
            [DropdownItem.name]:DropdownItem,
            [Divider.name]:Divider,
            [NavBar.name]: NavBar,
            [List.name]: List,
            rxNavBar,
          [Picker.name]: Picker,
          [Popup.name]: Popup,
            [PullRefresh.name]: PullRefresh
        },
        data() {
            return {
                isLoading : false,
                //判断列表是否为空，是否显示空状态图
                isEmptyFlag:false,
                flag:false,
                page: 1,
                loading: false,
                finished: false,
                inputValue:'',
                number:1,
                type:'未审批',
                typeList:[{dictionaryValue: '',dictionaryTitle: '全部审批'}],
                isShow:false,
                name:"",
                value1:"3",
                searchDate:"",
                option: [

                ],
                datas:{
                    //每次3条
                    numberPage:'3',
                    approvalStatus:'0',
                    user_id:globaluserId(),
                    afterRentType:'0',
                },
                orders: [],
                authButtonsList:[]//权限列表
            };
        },
        created() {
            this.getStaffFuntionModelList()
            this.initChooseTypes()
            // 记录用户的下拉选择，用于查看详情之后返回的初始化
            let currentLabel = JSON.parse(localStorage.getItem('currentLabel')); //先读取local里存储的历史记录
            if(currentLabel != null){
                if(currentLabel != '' ){
                    this.number = Number(currentLabel);
                }
            }
            if(JSON.parse(localStorage.getItem('inputValue'))){
                this.inputValue = JSON.parse(localStorage.getItem('inputValue'))
            }
        },
        beforeRouteEnter(to, from, next) {
            //清除本地localStorage缓存
            if(from.name == null){
                localStorage.removeItem('currentLabel');
                localStorage.removeItem('type');
                localStorage.removeItem('inputValue');
                localStorage.removeItem('currentLabelPublic');
            }
            next()
        },
        beforeRouteLeave(to, from, next){
            if(to.query.auditOrCheck == '2'){
                from.meta.keepAlive = true;
            }else {
                from.meta.keepAlive = false;
            }
            next()
        },
        methods:{
            onRefresh() {
                this.orders = []
                this.finished = false;
                this.page = 1
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.isLoading = false
                this.onLoad();
                responseUtil.alertMsg(this,"刷新成功")
            },
            //获取权限方法
            getStaffFuntionModelList () {
                var that = this
                let data = {}
                data.staff_id = getStaffId()
                data.menuName = 'distributionApplication_index'
                getStaffFuntionModelList(data).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.authButtonsList = response.data.data.data
                    })
                })
            },
            checkAuthList,

            //搜索
            onSearch(){
            },
            //显示隐藏下拉菜单，监听div点击事件，实现点击空白处隐藏下拉列表
            showOrHidden: function(){
                this.isShow = !this.isShow;
                var sp = document.getElementById("downList");
                if(sp){
                    document.addEventListener('click',e =>{
                        if(!sp.contains(e.target)){
                            this.isShow = false;
                        }
                    })
                }
            },
            //返回上一层
            leftReturn(){
                this.$router.go(-1);
            },
            // 待审批点击审核，进入配货审核详情
            auditDetailClick(index){
                this.$router.push({
                    name:'distributionDetails',
                    query:{
                        auditOrCheck:'1',
                        id:this.orders[index].id
                    }
                });
            },
            //装修申请方案查看
            decorationSchemeAuditCheck(index){
                this.$router.push({
                    name:'distributionDetails',
                    query:{
                        auditOrCheck:'2',
                        id:this.orders[index].id
                    }
                });
            },

            //下拉菜单初始化选择类型
            initChooseTypes:function(){
                let that = this
                let initData = {}
                initData.dbName = ['description']
                initData.fdName = ['APPROVESTATUSMAP']
                queryBaseData(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        let list = response.data.data.APPROVESTATUSMAP
                        for(let i=0;i<list.length;i++){
                            that.typeList.push(list[i])
                        }
                    })
                })
            },

            //更改选择类型
            changeType:function(value,index){
                //将选择的类型放入本地内存中，
                if(index == ''){
                    index = 0;
                }
                localStorage.setItem('currentLabel',JSON.stringify(index))
                this.flag=true
                this.orders = [];
                this.page = 1;
                this.finished = false;
                this.isShow = false;
                this.number= index;
                this.type = this.typeList[index];
                this.loading = true;
                this.onLoad();
            },

            //下拉加载
            onLoad:function(){
                var that = this
                let initData = that.datas
                that.datas.approvalStatus = that.number>0 ? that.number-1 : ''
                //下拉刷新的页数
                initData.currentPage = that.page+''
                initData.searchName = that.inputValue
                initData.staff_id = getStaffId()
                initData.type = "3"
                distributionListInit(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.count =  response.data.data.pageCount
                        let list = response.data.data.data
                        for(let i=0;i<list.length;i++){
                            that.orders.push(list[i])
                        }

                        // 加载状态结束
                        that.loading = false;
                        that.page++;
                        // 数据全部加载完成
                        if (that.orders.length >= that.count) {
                            that.finished = true;
                            //console.log(that.finished)
                        }
                        if(that.orders.length == 0){
                            that.isEmptyFlag = true;
                        }else{
                            that.isEmptyFlag = false;
                        }
                    })
                })
            },
            //搜索框查询
            goSearch(){
                this.orders=[]
                this.page = 1;
                if(this.inputValue!=''){
                    localStorage.setItem('inputValue',JSON.stringify(this.inputValue))
                }else{
                    localStorage.removeItem('inputValue')
                }
                this.finished = false;
                this.loading = true;
                this.onLoad()

            },
        }
    }
</script>

<style lang="less" scoped >
    .nav_bar_title{
        z-index: 99;
    }
    .head{
        width: 100%;
        position: fixed;
        z-index: 8;
        overflow: hidden;
        background: #f8f8f8;
    }
    .search{
        margin: 8px 20px 0 20px;
        height: 36px;
        border-radius: 30px;
        background-color: rgba(244, 244, 244, 1);
        display: flex;
        align-items: center;
    }
    .search-icon{
        width: 19px;
        height: 19px;
        margin-left: 9px;
        background-image: url("../../assets/images/search-icon.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    input{
        font-size: 14px;
        margin-left: 10px;
        background-color: rgba(244, 244, 244, 1);
        border: 0;
        width: 250px;
    }
    input::-webkit-input-placeholder{
        color: rgba(199, 199, 199, 1);
    }
    .types{
        width: 100%;
        height: 30px;
        display: flex;
    }
    .type{
        width:auto;
        height: 17px;
        line-height: 17px;
        text-align: left;
        color: #fe5e3a;
        margin: 15px 0 0 15px;
        color: rgba(255, 93, 59, 1);
        font-size: 13px;
        font-family: PingFangSC-Regular;
    }
    .small-icon{
        width: 8px;
        height: 8px;
        margin: 19px 0 0 5px;
        background-image: url("../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 90%;
    }
    .small-icon-rotate{
        width: 8px;
        height: 8px;
        margin: 18px 0 0 5px;
        background-image: url("../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 90%;
        transform:rotate(180deg);
    }
    .choose-select{
        position: fixed;
        z-index: 6;
        padding-top: 90px;
        width: 100%;
    }
    .selectList{
        width: 100%;
        height: auto;
        padding: 5px 0 14px 0;
        background-color: white;
    }
    .select{
        width: 100%;
        height: 35px;
        line-height: 30px;
        font-size: 14px;
        text-align: left;
        margin-left: 15px;
        color: #999999;
    }
    .selectActive{
        color: #fe5e3a;
    }
    .h-line{
        height: 1px;
        margin: 0 5px 0 10px;
        background-color: white;
    }

    /*    下拉菜单*/
    .downMenuDiv{
        width: 2.2rem;
    }

    .auditDiv{
        padding-top: 80px;
    }
    /*    循环列表DIV*/
    .stateList{
        border-radius: 0.3rem;
        width: 93%;
        margin-left: 0.35rem;
        position: relative;
        margin-bottom: 0.4rem;
        background-color: #ffffff;
        padding-bottom: 0.5rem;
    }
    .stateUnapproval,.statePassed,.stateRejected{
        height: 0.45rem;
        width: fit-content;
        padding: 0 0.13rem;
        border-radius: 0.2rem 0rem;
        font-size: 11px;
        text-align: center;
        color: #ffffff;
        line-height: 0.5rem;
    }
    /*列表状态样式 1是未审批*/
    .stateUnapproval{
        background: -webkit-linear-gradient(left,#FFC274,#FF5D3B);
    }
    /*列表状态样式 2是已通过*/
    .statePassed{
        background: -webkit-linear-gradient(left,#66CB94,#28D3B0);
    }
    /*列表状态样式 3是已驳回*/
    .stateRejected{
        background: -webkit-linear-gradient(left,#E02020,#FF431B);
    }
    /*列表头部红色重点信息*/
    .headSpan{
        float: left;
        margin-right: 0.1rem;
        color: #FF5D3B;
    }
    /*列表头部小圆点*/
    .dot{
        border-radius: 50%;
        width: 0.01rem;
        height: 0.01rem;
        border: 0.05rem solid black;
        float: left;
        margin-top: 0.24rem;
        margin-right: 0.1rem;
    }
    /*列表头部信息*/
    .headInfo{
        margin-top: 0.4rem;
        margin-left: 0.4rem;
        font-size: 15px;
        font-weight: bold;
    }
    /*列表详情*/
    .listDetail{
        position: relative;
        margin-top: 0.5rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        padding-bottom: 0.5rem;
    }
    .listDetail:after{
        content: "";
        display: block;
        clear: both;
    }
    /*    申请人信息DIV*/
    .proposerDiv{
        margin-left: 0.5rem;
        font-size: 13px;
        color: #999999;
        float: left;
    }
    /*分割线1*/
    .rule1{
        position: absolute;
        left: 2.3rem;
        top: 0.1rem;
        border: 0.01rem solid #f7f7f7;
        height: 1rem;
        float: left;
    }
    /*分割线2*/
    .rule2{
        position: absolute;
        left: 5.3rem;
        top: 0.1rem;
        border: 0.01rem solid #f7f7f7;
        height: 1rem;
        width: 0.01rem;
        float: left;
    }
    /*装修预算和改造户型*/
    .budgetDiv,.remouldDiv{
        font-size: 13px;
        color: #999999;
        margin-left: 1.68rem;
        float: left;
    }
    /*   装修预算 */
    .budgetDiv{
        width: 1.5rem;
        text-align: center;
    }
    /*    分割线2*/
    /* 申请人取值DIV*/
    .proposerValueDiv{
        margin-top: 0.3rem;
        font-size: 14px;
        color: #FF5D3B;
        height: 0.5rem;
        float: left;
        /*margin-left: 0.6rem;*/
        width: 2rem;
        /*border: 1px solid red;*/
        text-align: center;
    }
    /*装修预算和改造户型取值*/
    .budgetValueDiv,.remouldValueDiv{
        font-size: 14px;
        color: #FF5D3B;
        margin-top: 0.3rem;
        float: left;
        width: 2rem;
        /*border: 1px solid red;*/
        text-align: center;
    }
    .budgetValueDiv{
        margin-left: 0.8rem;
    }
    .remouldValueDiv{
        margin-left: 1.3rem;
    }
    /*    分割线3*/
    .rule3{
        border: 0.01rem solid #efefef;
        width: 100%;
        height: 0rem;
        margin-top: 0.1rem;
    }
    /*    申请原因*/
    .apply-result{
        margin-left: 0.5rem;
        margin-top: 0.3rem;
        line-height: 0.6rem;
        margin-bottom: 0.3rem;
        padding-right: 10px;
    }
    /*    尾部DIV*/
    .listFloor{
        margin-left: 0.5rem;
        margin-top: 0.35rem;
        height: 0.73rem;
        line-height: 0.73rem;
        display: flex;
        .createDiv{
            width: 70%;
        }
    }
    /*.listFloor :nth-of-type(1){*/
    /*    width: 70%;*/
    /*    border: 1px solid red;*/
    /*}*/


    /*    尾部 创建方式的createMethodsDiv*/
    .createMethodsDiv{
        font-size:13px ;
        color: #999999;
    }
    /*    尾部 创建时间的createTimeDiv*/
    .createTimeDiv{
        display: inline;
        font-size:13px ;
        margin-left: 0.2rem;
        color: #999999;
    }

    /*    下部按钮*/
    .btn1,.btn2{
        /*float: right;*/
        margin-right: 0.7rem;
        height: 0.82rem;
        width: 2.3rem;
        border-radius: 0.2rem;
        border: 0rem solid transparent;
        box-shadow: none;
        line-height: 0.7rem;
        font-size: 13px;
    }
    /*按钮*/
    .btn1{
        background: -webkit-linear-gradient(left,#FFC274,#FF5D3B);
        color: white;
    }
    .btn2{
        padding: 0.01rem;
        border: 0.03rem solid transparent;
        background-image: linear-gradient(white,white),
        linear-gradient(to right ,#FFC274,#FF5D3B);
        background-clip: padding-box,border-box;
        background-origin: border-box;
        color: #FF5D3B;
    }
</style>
